import { Fragment, useState, useEffect ,useMemo,useCallback} from 'react'
import { Dialog, Transition } from '@headlessui/react'
import React, { forwardRef, useImperativeHandle } from 'react';
import { BrowserRouter, Route, Link,Switch ,Redirect,useHistory} from "react-router-dom";
import { axios ,productStats} from '../Network'
import CartDetails from './Cart'
// import moment from 'moment'
import moment from 'moment-timezone'
import { compareAsc, format,setHours ,setMinutes} from 'date-fns'
const CartView = forwardRef((props, ref) => {
  const [show, setShow] = useState(false)
  let [isOpen, setIsOpen] = useState(false)
  let [currentView, setCurrentView] = useState(1)
  const [userDate, setDate] = useState(null)
  const history = useHistory();
  function closeModal() {
    setIsOpen(false)
  }
  useImperativeHandle(ref, () => ({
    openModal: () => {
      setIsOpen(true)
    }
  }));
  useEffect(() => {
    let userSeletedDate = localStorage.getItem('userQ')
    var date = JSON.parse(userSeletedDate)
    if (date != null) {

      setDate(moment(date.date).tz("Asia/Taipei").toDate())
    }else{
      setDate( moment(setHours(setMinutes(new Date(), 0), 9)).tz("Asia/Taipei"))
    }
  }, [])
  const newDate = () =>{
    var new_date = moment(new Date()).tz("Asia/Taipei");
    return  new_date.add(1, 'days');
  }

  function openModal() {
    setIsOpen(true)
  }
  useEffect(() => {
    // setShow(true)
  }, [])

  const displayColorBG =()=>{
    try {
      return props.storeDetails.storeOptions.colorTheme
    }catch{

    return 'white'
    }
  }

  function manageDrawer() {
    props.showDrawer(!show)
    setShow(!show)

  }

  const people = [
    {
      id: 1,
      unavailable: true,
      name: 'Michael'
    }

  ]

  function removeItem(e) {
    props.removeItem(e)
  }


  const  validateAddons = useMemo(()=> {
    var required = 1
    let allowed =  props.items.filter((item: { type: string; }) =>   item.type != "Food" && item.type != "Tart-Addons" && item.type != "Gifts" )
    return  allowed.length  >= required ? true : false
  },[props.items])
function redirectCheckout(){
  let path = `Cart`;
  props.history.push(path);
}

  function displayInitialCart(){
    try {
      let initialPrice:number = 0

    let total =   props.items.reduce((sum, current) => sum + Number(current.price), 0);
    return total
    } catch (error) {
      return 0
    }
  }
  function cart(){
    return (
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center bg-black bg-opacity-60">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Your Cart ({props.items.length})
                </Dialog.Title>
                <div className=" justify-center align-middle items-center place-items-center">
                <h3 className="mt-2 mb-5 text-black text-xs   flex leading-5">
              <span className='text-xs'>Selected date:</span> <span className="text-blue-800 text-xs font-bold ml-2"> {moment(userDate).tz("Asia/Taipei").tz("Asia/Taipei").format("MMMM Do YYYY ").toString()}</span><img src="https://img.icons8.com/material-outlined/344/calendar--v1.png" className="h-4 w-4 mx-2  " />
              </h3>
              </div>
                <div className="mt-5 bar overflow-auto" style={{height: props.items.length <= 3 ? 'auto' :400,overflow:'auto'}}>

                  <CartDetails items={props.items} removeItem={(e)=>removeItem(e)} showDelete={true}/>
                </div>

                <div className="mt-4">

{validateAddons ? null :<div
className="mb-4"
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center"
              }}
            >
            <p class="text-red-500 text-xs font-bold ">  FLOWERS REQUIRED</p>
                     <p category="c1" className="text-xs"style={{marginTop:10}}>Sorry, you can't purchase without flowers.</p>
                    </div> }

<button
  style={{backgroundColor:validateAddons ? displayColorBG().buttonColor : 'gray',height:45}}
   onClick={()=>history.push("/Cart")}
   disabled={validateAddons ? false :true}
  className="flex   align-middle items-center w-full justify-center transform bg-white hover:bg-gray-100 text-white text-xs rounded-full transition-all duration-300 z-50"
>
  <img
    src={"/lock@3x.png"}
    className=""
    style={{
      height: 16,
      width: 16,
      marginRight: 10,
    }}
  />
  <div className="  text-white text-sm flex  justify-center align-middle items-center">
    Checkout
  </div>
</button>
                  {/* <button
                    type="button"
                    disabled={validateAddons ? false :true}
                    className={`inline-flex w-full lg:w-1/2  justify-center px-4 py-2 text-xs font-medium text-white border border-transparent rounded-full focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 ` }
                    // ${validateAddons ? `bg-black hover:bg-gray-500  `:'bg-gray-700'}
                    style={{backgroundColor:validateAddons ? displayColorBG().buttonColor : 'gray'}}
                    onClick={()=>history.push("/Cart")}

                  >
             Checkout    {new Intl.NumberFormat('en-PH', { style: 'currency', currency: 'PHP' }).format(displayInitialCart())}
                  <a href={validateAddons ? "/Cart" : "javascript:void(0);"}  ><svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mx-2 " fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                    </svg></a>
                  </button> */}
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
        </Transition>

    )
  }
  function customerDetails(){
    return (
      <Transition appear show={isOpen} as={Fragment}>

      <Dialog
      as="div"
      className="fixed inset-0 z-10 overflow-y-auto"
      onClose={closeModal}
    >
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Your Cart ({props.items.length})
                </Dialog.Title>
                </div>
      </Dialog>
      </Transition>
    )
  }
  function detectCartView(e){

    switch (e) {

      case 1:
        return cart()
        break;
      case 2:
        return customerDetails()
      default:
        return <>Empty</>
        break;
    }
  }
  return (
    <div >
  {cart()}
      {/* <div hidden className="float-right border border-gray-500 hover:shadow-lg hover:border:black flex items-center justify-center px-4 py-3  font-medium full rounded-full    hover:bg-gray-900 md:py-4 md:text-lg md:px-10" style={{ float: 'bottom', position: 'fixed', marginLeft: '45%', position: 'fixed', right: 20, top: 20, zIndex: 20 ,
      backgroundColor:'white'

      }}>
             <a onClick={() => openModal()}><div class="text-center  flex ">
<img alt="cart_icon" src="https://localflowershop.sgp1.digitaloceanspaces.com/product/1729215962752-cart-icon-204.png" className='mr-2 w-18 h-18 ' style={{height:25}}/>
          {props.items.length === 0 ? null : <><p class="text-center base mx-0" onClick={() => openModal()} style={{ color: 'black' }}></p><p class="text-center base" style={{ color: 'black' }}>{props.items.length}</p> </>}
          </div>
        </a>
      </div> */}




    </div>



  )
});

export default CartView;
